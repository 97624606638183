@media (min-width: 0) {
  .card-deck .card {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    flex-basis: calc( 100% - 30px) !important; } }

@media (min-width: 576px) {
  .card-deck .card {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    flex-basis: calc( 100% - 30px) !important; } }

@media (min-width: 768px) {
  .card-deck .card {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    flex-basis: calc( 50% - 30px) !important; } }

@media (min-width: 992px) {
  .card-deck .card {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    flex-basis: calc( 33.33333% - 30px) !important; } }

@media (min-width: 1200px) {
  .card-deck .card {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    flex-basis: calc( 33.33333% - 30px) !important; } }

::-webkit-input-placeholder {
  color: #999 !important; }

:-ms-input-placeholder {
  color: #999 !important; }

::-moz-placeholder {
  color: #999 !important; }

:focus::-webkit-input-placeholder {
  color: #555 !important; }

:focus:-ms-input-placeholder {
  color: #555 !important; }

:focus::-moz-placeholder {
  color: #555 !important; }

.account-button {
  background-color: #00C734;
  border: none; }
  .account-button:hover {
    background-color: darkgrey; }

.productDetailTabDisplay {
  padding-top: 20px; }
  .productDetailTabDisplay .tabList {
    border-bottom: 1px solid #e9e9e9; }
    .productDetailTabDisplay .tabList button {
      border-radius: 0;
      border-bottom: 1px solid white;
      color: #aaa;
      position: relative;
      font-size: 14px;
      font-weight: 400;
      padding: 14px 0;
      cursor: pointer;
      outline: none;
      border: none;
      background-color: #fff;
      margin-right: 50px; }
      .productDetailTabDisplay .tabList button:hover {
        color: #52a43e; }
      .productDetailTabDisplay .tabList button:focus {
        outline: 0; }
      .productDetailTabDisplay .tabList button.active {
        color: #52a43e; }
        .productDetailTabDisplay .tabList button.active:before {
          content: "";
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid #eaeaea;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -2px;
          margin: auto; }
        .productDetailTabDisplay .tabList button.active:after {
          content: "";
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid #fff;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -2px;
          margin: auto; }
  .productDetailTabDisplay .tabContent {
    padding-top: 20px;
    /*> .section {
      &:first-child {
        img {
          display: none;
        }
      }
    }*/ }
    .productDetailTabDisplay .tabContent .faqs {
      list-style: none;
      padding: 0; }
      .productDetailTabDisplay .tabContent .faqs .faq {
        color: #666;
        margin-bottom: 20px;
        font-size: 13px;
        padding: 0; }
        .productDetailTabDisplay .tabContent .faqs .faq .question {
          font-weight: bold; }
        .productDetailTabDisplay .tabContent .faqs .faq:last-child {
          margin-bottom: 0; }
    .productDetailTabDisplay .tabContent[data-active='Regional Point Values'] {
      display: table;
      border-collapse: collapse;
      margin-top: 75px;
      position: relative; }
      .productDetailTabDisplay .tabContent[data-active='Regional Point Values']:before, .productDetailTabDisplay .tabContent[data-active='Regional Point Values']:after {
        padding: 8px;
        position: absolute;
        top: -36px;
        color: #333;
        font-weight: bold;
        border: 1px solid #eee;
        border-bottom-width: 2px; }
      .productDetailTabDisplay .tabContent[data-active='Regional Point Values']:before {
        left: 0;
        content: 'Region';
        width: calc(100% - 100px);
        border-right: 0; }
      .productDetailTabDisplay .tabContent[data-active='Regional Point Values']:after {
        right: -1px;
        content: 'Points';
        width: 101px; }
      .productDetailTabDisplay .tabContent[data-active='Regional Point Values'] > .section {
        display: table-row;
        /*&:first-child {
          background-color: $color-green;
          color: #fff;
        }*/ }
        .productDetailTabDisplay .tabContent[data-active='Regional Point Values'] > .section > span {
          display: table-cell;
          border: 1px solid #eee;
          padding: 5px 8px; }
          .productDetailTabDisplay .tabContent[data-active='Regional Point Values'] > .section > span .colon {
            display: none; }
          .productDetailTabDisplay .tabContent[data-active='Regional Point Values'] > .section > span:last-child {
            width: 100px; }

body {
  margin: 0 auto;
  height: 100%;
  overflow-y: scroll !important; }

a {
  cursor: pointer;
  text-decoration: none !important; }

.loginBox {
  display: flex;
  flex-direction: 'row'; }
  .loginBox .select {
    width: 200px;
    margin-right: 100px;
    z-index: 1; }

footer > div:first-child {
  padding: 20px;
  margin-top: 20px; }
  footer > div:first-child > div:first-child {
    text-align: center;
    width: 100%; }
    footer > div:first-child > div:first-child .poweredBy {
      font-size: 18px;
      line-height: 18px;
      font-weight: 400;
      padding: 4px 0 0 5px;
      color: #999;
      display: inline-block;
      position: relative;
      white-space: nowrap; }
      footer > div:first-child > div:first-child .poweredBy:before {
        content: "";
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% + 120px);
        height: 1px;
        background: #e6e6e6;
        background: -webkit-gradient(linear, 0 0, 100% 0, from(#fbf9f9), to(#fbf9f9), color-stop(50%, #ddd)); }
      footer > div:first-child > div:first-child .poweredBy a {
        color: #333; }
        footer > div:first-child > div:first-child .poweredBy a:hover {
          color: #000; }

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  z-index: 99999;
  opacity: 0.25; }

.dimmed {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); }

.detail.container .backButton {
  display: block;
  margin: -10px 0 20px -5px; }
  .detail.container .backButton a {
    font-size: 16px;
    font-weight: 300;
    font-style: italic;
    -webkit-transition: color 200ms;
    -o-transition: color 200ms;
    transition: color 200ms;
    text-decoration: none; }
    .detail.container .backButton a svg {
      margin-right: 6px; }

.detail.container .card .card-body {
  font-size: 13px; }
  .detail.container .card .card-body h1,
  .detail.container .card .card-body h2,
  .detail.container .card .card-body h3,
  .detail.container .card .card-body h4,
  .detail.container .card .card-body h5,
  .detail.container .card .card-body h6 {
    font-size: initial;
    font-weight: initial;
    line-height: initial; }
  .detail.container .card .card-body .description {
    padding-bottom: 25px; }
  .detail.container .card .card-body .section {
    /*&:last-child, &.checkbox, &.radio {
          border-top: 1px solid #eee;
          padding-top: 35px;
          margin-top: 35px;
        }*/ }
    .detail.container .card .card-body .section + .section {
      margin-top: 25px; }
    .detail.container .card .card-body .section select {
      margin-left: 15px; }
    .detail.container .card .card-body .section > h2, .detail.container .card .card-body .section .label {
      margin-bottom: 10px;
      font-size: 15px; }
    .detail.container .card .card-body .section .form-control {
      width: 200px;
      margin-top: 6px;
      color: #555; }
    .detail.container .card .card-body .section .dropdown > button {
      border-color: #ced4da;
      color: #999; }
      .detail.container .card .card-body .section .dropdown > button.selected {
        color: #555; }
      .detail.container .card .card-body .section .dropdown > button:hover {
        background-color: #fff; }
      .detail.container .card .card-body .section .dropdown > button:focus {
        border-color: #80bdff;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        color: #555; }
    .detail.container .card .card-body .section .dropdown.show > button {
      background-color: #fff; }
    .detail.container .card .card-body .section .dropdown .dropdown-menu > button {
      font-size: 13px; }
      .detail.container .card .card-body .section .dropdown .dropdown-menu > button:focus {
        outline: 0; }
      .detail.container .card .card-body .section .dropdown .dropdown-menu > button:active {
        background-color: #fff;
        color: #333; }
    .detail.container .card .card-body .section .invalid > button {
      border-color: red; }
    .detail.container .card .card-body .section .checkoption {
      height: 25px;
      color: #999; }
      .detail.container .card .card-body .section .checkoption > div {
        display: inline-block; }
        .detail.container .card .card-body .section .checkoption > div input[type=checkbox] {
          -webkit-appearance: none;
          background-color: #fafafa;
          border: 1px solid #cacece;
          padding: 9px;
          border-radius: 3px;
          display: inline-block;
          position: relative;
          margin-right: 10px;
          bottom: -5px; }
          .detail.container .card .card-body .section .checkoption > div input[type=checkbox]:active {
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1); }
          .detail.container .card .card-body .section .checkoption > div input[type=checkbox]:checked {
            background-color: #e9ecee;
            border: 1px solid #adb8c0;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
            color: #99a1a7; }
            .detail.container .card .card-body .section .checkoption > div input[type=checkbox]:checked:after {
              content: '\2714';
              font-size: 14px;
              position: absolute;
              top: 0px;
              left: 3px;
              color: #99a1a7; }
          .detail.container .card .card-body .section .checkoption > div input[type=checkbox]:focus {
            outline: 0; }
        .detail.container .card .card-body .section .checkoption > div:hover {
          cursor: pointer;
          color: #555; }
          .detail.container .card .card-body .section .checkoption > div:hover input[type=checkbox] {
            border-color: #aaa; }
      .detail.container .card .card-body .section .checkoption.checked {
        color: #555; }
      .detail.container .card .card-body .section .checkoption .dropdown {
        display: inline-block;
        padding-left: 10px; }
        .detail.container .card .card-body .section .checkoption .dropdown > button {
          padding: 1px 6px;
          font-size: 10px; }
    .detail.container .card .card-body .section .documents {
      font-size: 12px; }
      .detail.container .card .card-body .section .documents th {
        border-color: #e2e2e2;
        border-bottom-width: 1px;
        background-color: #fcfcfc; }
        .detail.container .card .card-body .section .documents th:first-child {
          width: 65px;
          border-left: 0; }
        .detail.container .card .card-body .section .documents th:last-child {
          width: 120px;
          border-right: 0; }
      .detail.container .card .card-body .section .documents tr {
        border: 0; }
        .detail.container .card .card-body .section .documents tr:hover td {
          background-color: #fafafa; }
        .detail.container .card .card-body .section .documents tr:last-child td {
          border-bottom: 0; }
      .detail.container .card .card-body .section .documents td {
        border-color: #ebebeb;
        padding: 10px;
        vertical-align: middle; }
        .detail.container .card .card-body .section .documents td:first-child {
          text-align: center;
          border-left: 0; }
          .detail.container .card .card-body .section .documents td:first-child a {
            font-size: 20px;
            text-align: center; }
        .detail.container .card .card-body .section .documents td:last-child {
          border-right: 0; }

.login {
  padding: 15px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around; }
  .login .back {
    width: 100%;
    padding: 0;
    color: blue;
    background-color: #ffffff;
    border: none; }
  .login .forgot {
    width: 100%;
    padding: 0;
    color: blue;
    background-color: #ffffff;
    border: none; }
  .login button {
    width: 100%; }
  .login .alert-danger {
    margin-top: 3px;
    margin-bottom: 0; }

.site .collapes {
  display: none; }

.site .navbar .navbar-collapse.collapse {
  display: block !important; }

.site .navbar .navbar-nav > li, .site .navbar .navbar-nav {
  float: left !important; }

.site .navbar .navbar-nav.navbar-right:last-child {
  margin-right: -15px !important; }

.site .navbar .navbar-right {
  float: right !important; }

.site .navbar .logo {
  height: 50px;
  width: auto;
  position: relative;
  z-index: 500; }

.site .navbar button {
  position: absolute;
  right: 20px;
  z-index: 99999;
  top: 22px; }

.site > .container-fluid {
  padding: 0;
  flex-grow: 1; }

.site .priceTime {
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 14px;
  color: #666666; }
  .site .priceTime span + span:before {
    content: "|";
    font-weight: 300;
    color: #999;
    position: relative;
    margin: 0 5px; }

@media (max-width: 600px) {
  .site .page .options {
    flex-direction: column; } }

.site .page .options {
  display: flex;
  justify-content: center;
  margin: 0 0px 20px 0px;
  position: relative;
  align-items: center;
  /*&:not(.sowBuilderEmpty) {
        justify-content: left;
      }*/ }
  .site .page .options > .search {
    border-radius: 0;
    position: relative;
    width: 27%;
    margin-right: 20px; }
    .site .page .options > .search .fa-times {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #ccc;
      -webkit-transition: color 200ms;
      -moz-transition: color 200ms;
      -o-transition: color 200ms;
      transition: color 200ms; }
      .site .page .options > .search .fa-times:hover {
        color: #999;
        cursor: pointer; }
  .site .page .options > .listGridToggle {
    white-space: nowrap;
    margin-right: 50px; }
    .site .page .options > .listGridToggle button {
      border-radius: 0; }
      .site .page .options > .listGridToggle button + button {
        margin-left: 5px; }
      .site .page .options > .listGridToggle button:not(.active) {
        opacity: 0.2; }
      .site .page .options > .listGridToggle button.active {
        opacity: 0.4; }
  .site .page .options > .price {
    width: 50%;
    max-width: 200px;
    margin: 25px 15px 0px 0px; }
    .site .page .options > .price .input-range {
      height: auto;
      top: -1px; }
      .site .page .options > .price .input-range .input-range__track--active {
        background: #e2e2e2 !important;
        height: 1px; }
      .site .page .options > .price .input-range:before {
        display: none;
        position: absolute;
        content: "";
        bottom: -18px;
        left: calc(50% + 2px);
        width: 1px;
        height: 6px;
        background-color: #ddd;
        bottom: -17px; }
      .site .page .options > .price .input-range .input-range__track--background {
        height: 1px !important; }
        .site .page .options > .price .input-range .input-range__track--background:before {
          display: none;
          position: absolute;
          content: "";
          bottom: -18px;
          left: calc(50% + 2px);
          width: 1px;
          height: 6px;
          background-color: #ddd;
          left: 2px; }
        .site .page .options > .price .input-range .input-range__track--background:after {
          display: none;
          position: absolute;
          content: "";
          bottom: -18px;
          left: calc(50% + 2px);
          width: 1px;
          height: 6px;
          background-color: #ddd;
          left: auto;
          right: -8px; }
      .site .page .options > .price .input-range .input-range__slider {
        background: #f5f5f5 !important;
        border: 1px solid #e2e2e2 !important;
        border-radius: 0 !important;
        width: 15px !important;
        height: 15px !important;
        margin-top: -8px !important; }
        .site .page .options > .price .input-range .input-range__slider:hover {
          border: 1px solid #ccc !important; }
        .site .page .options > .price .input-range .input-range__slider:active {
          background: #fcfcfc !important;
          border: 1px solid #ccc !important;
          box-shadow: rgba(0, 0, 0, 0.025) 0px 1px 6px, rgba(0, 0, 0, 0.025) 0px 1px 4px !important; }
      .site .page .options > .price .input-range .input-range__label--min,
      .site .page .options > .price .input-range .input-range__label--max {
        display: none; }
      .site .page .options > .price .input-range .input-range__label--value {
        top: -28px;
        left: -1.5px;
        font-size: 11.5px;
        color: #999; }
      .site .page .options > .price .input-range .input-range__slider-container .input-range__label--value {
        margin-left: -3px; }
      .site .page .options > .price .input-range .input-range__slider-container .input-range__slider {
        margin-left: -10px;
        margin-right: 0; }
      .site .page .options > .price .input-range .input-range__slider-container + .input-range__slider-container .input-range__label--value {
        margin-left: 8px; }
      .site .page .options > .price .input-range .input-range__slider-container + .input-range__slider-container .input-range__slider {
        margin-left: 0;
        margin-right: -10px; }
    .site .page .options > .price.filtered .input-range__track--active {
      background: #ccc !important; }
    .site .page .options > .price.filtered .input-range__label--value {
      color: #666; }
    .site .page .options > .price.filtered .input-range__slider {
      background: #fcfcfc !important;
      border: 1px solid #ccc !important; }
  .site .page .options .sowBuilderSummary {
    padding: 10px 0;
    margin-left: 50px;
    width: 250px;
    box-sizing: border-box; }
    .site .page .options .sowBuilderSummary > div {
      padding: 0 12px; }
    .site .page .options .sowBuilderSummary .summary > div {
      line-height: 12px;
      font-size: 10px; }
    .site .page .options .sowBuilderSummary .summary .variables > input {
      font-size: 10px;
      line-height: 18px; }
      .site .page .options .sowBuilderSummary .summary .variables > input:first-child {
        width: 115px; }
      .site .page .options .sowBuilderSummary .summary .variables > input:last-child {
        width: 90px; }
    .site .page .options .sowBuilderSummary > .cta {
      display: none;
      position: absolute;
      top: 7.5px;
      right: 0;
      margin-top: 0; }
      .site .page .options .sowBuilderSummary > .cta button {
        display: block;
        width: 85px;
        padding: 0 10px !important;
        margin-right: 0;
        text-align: left;
        box-sizing: border-box;
        font-size: 10px;
        line-height: 20px; }
        .site .page .options .sowBuilderSummary > .cta button + button {
          margin-top: 2px; }
        .site .page .options .sowBuilderSummary > .cta button.add, .site .page .options .sowBuilderSummary > .cta button.added {
          display: none; }
        .site .page .options .sowBuilderSummary > .cta button.download {
          height: 20px;
          padding: 0 !important; }
          .site .page .options .sowBuilderSummary > .cta button.download:after {
            top: -2px;
            font-size: 11px; }
          .site .page .options .sowBuilderSummary > .cta button.download .label {
            line-height: 20px;
            height: 20px;
            padding: 0 29px 0 10px !important; }
          .site .page .options .sowBuilderSummary > .cta button.download .docTypes {
            height: 20px; }
            .site .page .options .sowBuilderSummary > .cta button.download .docTypes i {
              height: 20px; }
              .site .page .options .sowBuilderSummary > .cta button.download .docTypes i:after {
                top: 0;
                height: 20px;
                line-height: 20px;
                font-size: 14px; }
          @media (max-width: 1400px) {
            .site .page .options .sowBuilderSummary > .cta button.download:hover .label {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              border-top-right-radius: 3px;
              border-bottom-right-radius: 3px; }
            .site .page .options .sowBuilderSummary > .cta button.download:hover .docTypes {
              border-top-left-radius: 3px;
              border-bottom-left-radius: 3px;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              border-left: 0;
              border-right: 1px solid rgba(0, 0, 0, 0.2);
              left: 0;
              right: auto;
              transform: translateX(-100%);
              -moz-transform: translateX(-100%);
              -webkit-transform: translateX(-100%);
              -o-transform: translateX(-100%);
              -webkit-box-shadow: inset -13px 0px 5px -10px rgba(0, 0, 0, 0.075);
              -moz-box-shadow: inset -13px 0px 5px -10px rgba(0, 0, 0, 0.075);
              box-shadow: inset -13px 0px 5px -10px rgba(0, 0, 0, 0.075); } }
          .site .page .options .sowBuilderSummary > .cta button.download:hover + .preview {
            opacity: 1; }
        .site .page .options .sowBuilderSummary > .cta button.preview:after {
          top: -3px; }
    .site .page .options .sowBuilderSummary.has-products > .cta {
      display: block; }
      .site .page .options .sowBuilderSummary.has-products > .cta button.preview {
        display: block; }
    .site .page .options .sowBuilderSummary.preview-showing {
      border-color: #737373;
      z-index: 10000; }
  .site .page .options.sowBuilderEmpty .sowBuilderSummary {
    display: none; }

.site .page .error {
  text-align: center;
  font-style: italic;
  color: #999;
  padding-top: 10px;
  padding-bottom: 40px; }

.site.list .page .card {
  margin-bottom: 20px;
  border-radius: 0; }
  .site.list .page .card:hover {
    cursor: pointer;
    border-color: #cacdd0;
    background-color: #fcfcfc;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 8px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 8px;
    -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 8px; }
  .site.list .page .card .card-header {
    height: 150px;
    background: none;
    border-bottom: 0;
    padding-bottom: 0; }
    .site.list .page .card .card-header .card-title {
      font-size: 17px;
      font-weight: 600;
      padding-bottom: 12px; }
  .site.list .page .card .card-body {
    padding-top: 0;
    padding-bottom: 0; }
    .site.list .page .card .card-body .card-text {
      font-size: 12px; }
  .site.list .page .card .card-footer {
    border-top: 0;
    background: none; }
    .site.list .page .card .card-footer button {
      border: 0;
      border-radius: 0;
      font-size: 13px; }

.site.detail .navbar {
  margin-bottom: 50px; }
  .site.detail .navbar .logo {
    top: 0; }

.site.detail .page > .container > .btn-link {
  display: block;
  margin: 20px 0 20px -12px;
  color: #999;
  font-size: 15px;
  font-style: italic;
  -webkit-transition: color 200ms;
  -moz-transition: color 200ms;
  -o-transition: color 200ms;
  transition: color 200ms; }
  .site.detail .page > .container > .btn-link:focus {
    outline: 0; }
  .site.detail .page > .container > .btn-link:hover {
    color: #666;
    text-decoration: none; }
  .site.detail .page > .container > .btn-link svg {
    margin-right: 6px; }

.site.detail .page > .container > .card {
  margin-bottom: 20px; }

/* CUSTOM */
@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 400;
  src: url("https://www.ptc.com/dist/ptc/fonts/openSans/OpenSans-Regular.woff2") format("woff2"), url("https://www.ptc.com/dist/ptc/fonts/openSans/OpenSans-Regular.woff") format("woff"), url("https://www.ptc.com/dist/ptc/fonts/openSans/OpenSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "OpenSans";
  font-style: italic;
  font-weight: 400;
  src: url("https://www.ptc.com/dist/ptc/fonts/openSans/OpenSans-Italic.woff2") format("woff2"), url("https://www.ptc.com/dist/ptc/fonts/openSans/OpenSans-Italic.woff") format("woff"), url("https://www.ptc.com/dist/ptc/fonts/openSans/OpenSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 600;
  src: url("https://www.ptc.com/dist/ptc/fonts/openSans/OpenSans-Semibold.woff2") format("woff2"), url("https://www.ptc.com/dist/ptc/fonts/openSans/OpenSans-Semibold.woff") format("woff"), url("https://www.ptc.com/dist/ptc/fonts/openSans/OpenSans-Semibold.ttf") format("truetype"); }

@font-face {
  font-family: "OpenSans";
  font-style: italic;
  font-weight: 600;
  src: url("https://www.ptc.com/dist/ptc/fonts/openSans/OpenSans-SemiboldItalic.woff2") format("woff2"), url("https://www.ptc.com/dist/ptc/fonts/openSans/OpenSans-SemiboldItalic.woff") format("woff"), url("https://www.ptc.com/dist/ptc/fonts/openSans/OpenSans-SemiboldItalic.ttf") format("truetype"); }

@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 700;
  src: url("https://www.ptc.com/dist/ptc/fonts/openSans/OpenSans-Bold.woff2") format("woff2"), url("https://www.ptc.com/dist/ptc/fonts/openSans/OpenSans-Bold.woff") format("woff"), url("https://www.ptc.com/dist/ptc/fonts/openSans/OpenSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "OpenSans";
  font-style: italic;
  font-weight: 700;
  src: url("https://www.ptc.com/dist/ptc/fonts/openSans/OpenSans-BoldItalic.woff2") format("woff2"), url("https://www.ptc.com/dist/ptc/fonts/openSans/OpenSans-BoldItalic.woff") format("woff"), url("https://www.ptc.com/dist/ptc/fonts/openSans/OpenSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: "CenturyGothic";
  font-style: normal;
  font-weight: 400;
  src: url("https://www.ptc.com/dist/ptc/fonts/centuryGothic/CenturyGothic.woff2") format("woff2"), url("https://www.ptc.com/dist/ptc/fonts/centuryGothic/CenturyGothic.ttf") format("truetype"); }

@font-face {
  font-family: "CenturyGothic";
  font-style: italic;
  font-weight: 400;
  src: url("https://www.ptc.com/dist/ptc/fonts/centuryGothic/CenturyGothic-Italic.woff2") format("woff2"), url("https://www.ptc.com/dist/ptc/fonts/centuryGothic/CenturyGothic-Italic.ttf") format("truetype"); }

@font-face {
  font-family: "CenturyGothic";
  font-style: normal;
  font-weight: 700;
  src: url("https://www.ptc.com/dist/ptc/fonts/centuryGothic/CenturyGothic-bold.woff2") format("woff2"), url("https://www.ptc.com/dist/ptc/fonts/centuryGothic/CenturyGothic-bold.ttf") format("truetype"); }

@font-face {
  font-family: "CenturyGothic";
  font-style: italic;
  font-weight: 700;
  src: url("https://www.ptc.com/dist/ptc/fonts/centuryGothic/CenturyGothic-boldItalic.woff2") format("woff2"), url("https://www.ptc.com/dist/ptc/fonts/centuryGothic/CenturyGothic-boldItalic.ttf") format("truetype"); }

* {
  font-family: "OpenSans", sans-serif; }

.site .navbar {
  min-height: 80px;
  background: transparent !important; }
  .site .navbar .logo {
    height: 55px;
    top: 50px; }

.site .page .hero {
  background: url(../assets/hero.jpg) center 0 no-repeat;
  background-size: cover;
  margin: -81px 0 100px 0;
  padding-top: 60px;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #ccc;
  /*&:after {
        position:absolute;
        z-index: 1;
        content:'';
        background-color: $color-triangle-green;
        width: 100%;
        height: 615px;
        right: -18%;
        top: -475px;
        transform: rotate(22deg);
        /!*-moz-transform: rotate(15deg);
        -webkit-transform: rotate(15deg);
        -o-transform: rotate(15deg);*!/
      }*/ }
  .site .page .hero:before {
    position: absolute;
    z-index: 2;
    content: "";
    background-color: rgba(14, 18, 19, 0.8);
    width: 100%;
    height: 600px;
    left: -25%;
    top: -450px;
    transform: rotate(-24deg);
    -moz-transform: rotate(-24deg);
    -webkit-transform: rotate(-24deg);
    -o-transform: rotate(-24deg); }
  .site .page .hero > div {
    /*&:after {
          position:absolute;
          z-index: 1;
          content:'';
          background-color: $color-triangle-green;
          width: 100%;
          height: 500px;
          right: -22%;
          bottom: -500px;
          transform: rotate(-32deg);
          -moz-transform: rotate(-32deg);
          -webkit-transform: rotate(-32deg);
          -o-transform: rotate(-32deg);
        }*/ }
    .site .page .hero > div .titles {
      position: relative;
      z-index: 9999;
      top: 400px;
      left: 0;
      padding: 20px 10px;
      color: #fff;
      background-color: rgba(83, 186, 37, 0.8); }
      .site .page .hero > div .titles .title {
        font-size: 35px;
        font-family: "CenturyGothic", Helvetica, Arial, sans-serif;
        text-transform: none; }
      .site .page .hero > div .titles .subtitle {
        font-size: 13px;
        line-height: 1.5;
        font-weight: 400; }
    .site .page .hero > div > .container {
      padding-top: 450px; }
      .site .page .hero > div > .container .featured {
        position: relative;
        z-index: 9999;
        margin: 0 -30px 25px -30px; }
        .site .page .hero > div > .container .featured .product-featured .card {
          border: 0;
          border-radius: 0;
          color: #fff;
          text-align: center;
          background: rgba(29, 29, 29, 0.8); }
          .site .page .hero > div > .container .featured .product-featured .card .card-header {
            position: relative;
            padding-top: 40px; }
            .site .page .hero > div > .container .featured .product-featured .card .card-header:before, .site .page .hero > div > .container .featured .product-featured .card .card-header:after {
              position: absolute;
              content: "";
              top: -25px;
              left: 50%;
              transform: translateX(-50%);
              -moz-transform: translateX(-50%);
              -webkit-transform: translateX(-50%);
              -o-transform: translateX(-50%);
              width: 60px;
              height: 60px;
              border-radius: 50%; }
            .site .page .hero > div > .container .featured .product-featured .card .card-header:after {
              background: url(../assets/vrd-icon.png) center center no-repeat;
              background-size: auto 25px; }
            .site .page .hero > div > .container .featured .product-featured .card .card-header .card-title {
              padding-bottom: 0;
              margin-bottom: 0; }
            .site .page .hero > div > .container .featured .product-featured .card .card-header .card-subtitle {
              margin-top: 12px; }
          .site .page .hero > div > .container .featured .product-featured .card .card-body {
            display: none; }
          .site .page .hero > div > .container .featured .product-featured .card .card-footer {
            padding-top: 0; }
            .site .page .hero > div > .container .featured .product-featured .card .card-footer button {
              position: absolute;
              bottom: -15px;
              left: 50%;
              transform: translateX(-50%);
              -moz-transform: translateX(-50%);
              -webkit-transform: translateX(-50%);
              -o-transform: translateX(-50%); }
          .site .page .hero > div > .container .featured .product-featured .card:hover {
            background: rgba(29, 29, 29, 0.9); }
            .site .page .hero > div > .container .featured .product-featured .card:hover .card-body {
              display: block;
              padding-bottom: 15px; }
        .site .page .hero > div > .container .featured .product-featured .card.vrd .card-header:before {
          background-color: #ff4949; }
        .site .page .hero > div > .container .featured .product-featured .card.vrd .card-footer button {
          background-color: #ff4949; }
        .site .page .hero > div > .container .featured .product-featured .card.vrd:hover .card-header:before {
          background-color: #ff3030; }
        .site .page .hero > div > .container .featured .product-featured .card.vrd:hover .card-footer button {
          background-color: #ff3030; }
        .site .page .hero > div > .container .featured .product-featured .card.supportplan .card-header:before {
          background-color: #00afcc; }
        .site .page .hero > div > .container .featured .product-featured .card.supportplan .card-footer button {
          background-color: #00afcc; }
        .site .page .hero > div > .container .featured .product-featured .card.supportplan:hover .card-header:before {
          background-color: #0099b3; }
        .site .page .hero > div > .container .featured .product-featured .card.supportplan:hover .card-footer button {
          background-color: #0099b3; }
        .site .page .hero > div > .container .featured .product-featured .card.successplan .card-header:before {
          background-color: #e2700c; }
        .site .page .hero > div > .container .featured .product-featured .card.successplan .card-footer button {
          background-color: #e2700c; }
        .site .page .hero > div > .container .featured .product-featured .card.successplan:hover .card-header:before {
          background-color: #ca640b; }
        .site .page .hero > div > .container .featured .product-featured .card.successplan:hover .card-footer button {
          background-color: #ca640b; }
        .site .page .hero > div > .container .featured .product-featured .card.successpath .card-header:before {
          background-color: #fdc973; }
        .site .page .hero > div > .container .featured .product-featured .card.successpath .card-footer button {
          background-color: #fdc973; }
        .site .page .hero > div > .container .featured .product-featured .card.successpath:hover .card-header:before {
          background-color: #fdbf5a; }
        .site .page .hero > div > .container .featured .product-featured .card.successpath:hover .card-footer button {
          background-color: #fdbf5a; }
        .site .page .hero > div > .container .featured .product-featured .card.rapidoutcome .card-header:before, .site .page .hero > div > .container .featured .product-featured .card.successservice .card-header:before {
          background-color: #5bb745; }
        .site .page .hero > div > .container .featured .product-featured .card.rapidoutcome .card-footer button, .site .page .hero > div > .container .featured .product-featured .card.successservice .card-footer button {
          background-color: #5bb745; }
        .site .page .hero > div > .container .featured .product-featured .card.rapidoutcome:hover .card-header:before, .site .page .hero > div > .container .featured .product-featured .card.successservice:hover .card-header:before {
          background-color: #52a43e; }
        .site .page .hero > div > .container .featured .product-featured .card.rapidoutcome:hover .card-footer button, .site .page .hero > div > .container .featured .product-featured .card.successservice:hover .card-footer button {
          background-color: #52a43e; }
        .site .page .hero > div > .container .featured .product-featured .card.timebased .card-header:before {
          background-color: #0075be; }
        .site .page .hero > div > .container .featured .product-featured .card.timebased .card-footer button {
          background-color: #0075be; }
        .site .page .hero > div > .container .featured .product-featured .card.timebased:hover .card-header:before {
          background-color: #0099b3; }
        .site .page .hero > div > .container .featured .product-featured .card.timebased:hover .card-footer button {
          background-color: #0099b3; }
        .site .page .hero > div > .container .featured .product-featured .priceTime {
          color: #bdc5c6; }
    .site .page .hero > div .categories {
      position: relative;
      z-index: 999;
      background-color: rgba(249, 247, 244, 0.8); }
      .site .page .hero > div .categories .col {
        padding: 0 2px; }
        .site .page .hero > div .categories .col > div {
          padding: 10px;
          width: 100%;
          height: 100%;
          text-align: center;
          position: relative; }
          .site .page .hero > div .categories .col > div:before, .site .page .hero > div .categories .col > div:after {
            content: "";
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%; }
          .site .page .hero > div .categories .col > div:before {
            left: calc(-200% - 4px); }
          .site .page .hero > div .categories .col > div:after {
            right: calc(-200% - 4px); }
        .site .page .hero > div .categories .col .parent {
          font-size: 23px;
          font-weight: 700;
          padding-bottom: 10px; }
        .site .page .hero > div .categories .col .list {
          max-width: 400px;
          margin: 0 auto;
          position: relative;
          z-index: 999; }
          .site .page .hero > div .categories .col .list ul {
            list-style: none;
            margin: 0;
            padding: 0;
            max-height: 185px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: center;
            flex-basis: auto;
            flex-grow: 1; }
            .site .page .hero > div .categories .col .list ul li {
              margin: 2px 6px; }
              .site .page .hero > div .categories .col .list ul li button {
                background-color: #fff;
                font-size: 11px;
                width: 100%;
                text-align: left; }
                .site .page .hero > div .categories .col .list ul li button > svg {
                  margin-right: 8px; }
                .site .page .hero > div .categories .col .list ul li button:hover, .site .page .hero > div .categories .col .list ul li button.active {
                  background-color: #888;
                  color: #fff; }
                .site .page .hero > div .categories .col .list ul li button.vrd:hover, .site .page .hero > div .categories .col .list ul li button.vrd.active {
                  background-color: #ff4949; }
                .site .page .hero > div .categories .col .list ul li button.supportplan:hover, .site .page .hero > div .categories .col .list ul li button.supportplan.active {
                  background-color: #00afcc; }
                .site .page .hero > div .categories .col .list ul li button.successplan:hover, .site .page .hero > div .categories .col .list ul li button.successplan.active {
                  background-color: #e2700c; }
                .site .page .hero > div .categories .col .list ul li button.successpath:hover, .site .page .hero > div .categories .col .list ul li button.successpath.active {
                  background-color: #fdc973; }
                .site .page .hero > div .categories .col .list ul li button.rapidoutcome:hover, .site .page .hero > div .categories .col .list ul li button.rapidoutcome.active, .site .page .hero > div .categories .col .list ul li button.successservice:hover, .site .page .hero > div .categories .col .list ul li button.successservice.active {
                  background-color: #5bb745; }
                .site .page .hero > div .categories .col .list ul li button.timebased:hover, .site .page .hero > div .categories .col .list ul li button.timebased.active {
                  background-color: #0075be; }

.site .sowBuilderSummary {
  position: relative;
  z-index: 10;
  border: 1px solid #d3d7dc;
  background-color: #fbfbfb;
  padding: 15px 0;
  box-shadow: rgba(0, 0, 0, 0.035) 0 1px 8px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.035) 0 1px 8px;
  -moz-box-shadow: rgba(0, 0, 0, 0.035) 0 1px 8px;
  vertical-align: top;
  margin-left: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; }
  @media (max-width: 800px) {
    .site .sowBuilderSummary {
      grid-row-start: 1; } }
  .site .sowBuilderSummary > div {
    padding: 0 15px; }
  .site .sowBuilderSummary > .cta {
    margin-top: -15px; }
    .site .sowBuilderSummary > .cta button {
      display: inline-block;
      color: #fff;
      white-space: nowrap;
      text-align: center;
      background: #00afcc;
      text-decoration: none;
      border: 0;
      border-radius: 3px;
      font-size: 12px;
      padding: 5px 15px !important;
      margin-right: 10px;
      font-weight: 300;
      -webkit-transition: background 200ms ease;
      -moz-transition: background 200ms ease;
      -o-transition: background 200ms ease;
      transition: background 200ms ease; }
      .site .sowBuilderSummary > .cta button:last-child {
        margin-right: 0; }
      .site .sowBuilderSummary > .cta button:hover {
        background: #0099b3; }
      .site .sowBuilderSummary > .cta button:focus {
        outline: 0; }
      .site .sowBuilderSummary > .cta button.added {
        background: green;
        position: relative; }
        .site .sowBuilderSummary > .cta button.added:before {
          position: absolute;
          z-index: 1;
          top: -4px;
          left: -4px;
          content: "\f111";
          color: #fff;
          font: normal normal normal 14px/1 FontAwesome;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          text-shadow: 0.5px 0.5px 0 white; }
        .site .sowBuilderSummary > .cta button.added:after {
          position: absolute;
          z-index: 2;
          top: -4px;
          left: -4px;
          content: "\f056";
          color: red;
          font: normal normal normal 14px/1 FontAwesome;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          opacity: 0.75;
          -webkit-transition: opacity 200ms ease;
          -moz-transition: opacity 200ms ease;
          -o-transition: opacity 200ms ease;
          transition: opacity 200ms ease; }
        .site .sowBuilderSummary > .cta button.added:hover {
          background: #009300; }
          .site .sowBuilderSummary > .cta button.added:hover:after {
            opacity: 1; }
      .site .sowBuilderSummary > .cta button.download {
        display: none;
        padding: 0 !important;
        position: relative; }
        .site .sowBuilderSummary > .cta button.download .label {
          position: relative;
          top: 0;
          left: 0;
          line-height: 28px;
          height: 28px;
          padding: 0 29px 0 15px !important;
          background: #00afcc;
          border-radius: 3px;
          z-index: 2;
          -webkit-transition: all 400ms ease;
          -o-transition: all 400ms ease;
          transition: all 400ms ease; }
        .site .sowBuilderSummary > .cta button.download:after {
          position: absolute;
          z-index: 2;
          top: 2px;
          right: 8px;
          content: "\f019";
          color: #fff;
          font: normal normal normal 14px/1 FontAwesome;
          line-height: 25px;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          -webkit-transition: transform 400ms ease;
          -moz-transition: transform 400ms ease;
          -o-transition: transform 400ms ease;
          transition: transform 400ms ease;
          transition-delay: 400ms; }
        .site .sowBuilderSummary > .cta button.download .docTypes {
          position: absolute;
          z-index: 1;
          top: 0;
          right: 0;
          opacity: 0;
          background: #00afcc;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          border-left: 1px solid rgba(0, 0, 0, 0.2);
          height: 28px;
          padding: 0 8px;
          -webkit-box-shadow: inset 13px 0px 5px -10px rgba(0, 0, 0, 0.075);
          -moz-box-shadow: inset 13px 0px 5px -10px rgba(0, 0, 0, 0.075);
          box-shadow: inset 13px 0px 5px -10px rgba(0, 0, 0, 0.075);
          -webkit-font-smoothing: antialiased;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          -webkit-transition: all 400ms ease;
          -moz-transition: all 400ms ease;
          -o-transition: all 400ms ease;
          transition: all 400ms ease;
          transition-delay: 100ms; }
          .site .sowBuilderSummary > .cta button.download .docTypes i {
            display: inline-block;
            width: 30px;
            height: 28px;
            position: relative;
            text-align: center; }
            .site .sowBuilderSummary > .cta button.download .docTypes i:after {
              position: absolute;
              z-index: 3;
              width: 30px;
              height: 28px;
              top: -1px;
              left: 50%;
              transform: translateX(-50%);
              -moz-transform: translateX(-50%);
              -webkit-transform: translateX(-50%);
              -o-transform: translateX(-50%);
              border: 0;
              content: "\f1c1";
              font: normal normal normal 16px/1 FontAwesome;
              line-height: 28px;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              color: #fff; }
            .site .sowBuilderSummary > .cta button.download .docTypes i.word:after {
              content: "\f1c2";
              opacity: 0.5; }
            .site .sowBuilderSummary > .cta button.download .docTypes i.pdf:after {
              content: "\f1c1";
              opacity: 0.5; }
            .site .sowBuilderSummary > .cta button.download .docTypes i:hover:after, .site .sowBuilderSummary > .cta button.download .docTypes i.active:after {
              opacity: 1 !important; }
        .site .sowBuilderSummary > .cta button.download:hover .label {
          background: #0099b3;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
        .site .sowBuilderSummary > .cta button.download:hover .docTypes {
          transform: translateX(100%);
          -moz-transform: translateX(100%);
          -webkit-transform: translateX(100%);
          -o-transform: translateX(100%);
          width: auto;
          opacity: 1; }
        @media (max-width: 1400px) {
          .site .sowBuilderSummary > .cta button.download:hover .label {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px; }
          .site .sowBuilderSummary > .cta button.download:hover .docTypes {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-left: 0;
            border-right: 1px solid rgba(0, 0, 0, 0.2);
            left: 0;
            right: auto;
            transform: translateX(-100%);
            -moz-transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            -o-transform: translateX(-100%);
            -webkit-box-shadow: inset -13px 0px 5px -10px rgba(0, 0, 0, 0.075);
            -moz-box-shadow: inset -13px 0px 5px -10px rgba(0, 0, 0, 0.075);
            box-shadow: inset -13px 0px 5px -10px rgba(0, 0, 0, 0.075); } }
        .site .sowBuilderSummary > .cta button.download:hover + .preview {
          opacity: 0; }
      .site .sowBuilderSummary > .cta button.preview {
        display: none;
        padding-right: 23px !important;
        position: relative;
        opacity: 1;
        -webkit-transition: opacity 400ms ease;
        -o-transition: opacity 400ms ease;
        transition: opacity 400ms ease; }
        .site .sowBuilderSummary > .cta button.preview:after {
          position: absolute;
          z-index: 2;
          top: -1px;
          right: 8px;
          content: "\f107";
          color: #fff;
          font: normal normal normal 14px/1 FontAwesome;
          line-height: 25px;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          -webkit-transition: transform 400ms ease;
          -moz-transition: transform 400ms ease;
          -o-transition: transform 400ms ease;
          transition: transform 400ms ease;
          transition-delay: 400ms; }
  .site .sowBuilderSummary .summary {
    /*text-align: right;*/ }
    .site .sowBuilderSummary .summary > div {
      font-size: 12px;
      line-height: 15px;
      font-weight: normal;
      color: #888;
      white-space: nowrap; }
      .site .sowBuilderSummary .summary > div span {
        font-weight: bold;
        padding-left: 10px;
        color: #333; }
    .site .sowBuilderSummary .summary .variables {
      white-space: nowrap;
      margin-bottom: 10px; }
      .site .sowBuilderSummary .summary .variables > input {
        display: inline-block;
        border: 0;
        border-bottom: 1px solid #ddd;
        color: #666;
        font-size: 12px;
        line-height: 20px; }
        .site .sowBuilderSummary .summary .variables > input::-webkit-input-placeholder {
          color: #666 !important; }
        .site .sowBuilderSummary .summary .variables > input:-ms-input-placeholder {
          color: #666 !important; }
        .site .sowBuilderSummary .summary .variables > input::-moz-placeholder {
          color: #666 !important; }
        .site .sowBuilderSummary .summary .variables > input:focus::-webkit-input-placeholder {
          color: #666 !important; }
        .site .sowBuilderSummary .summary .variables > input:focus:-ms-input-placeholder {
          color: #666 !important; }
        .site .sowBuilderSummary .summary .variables > input:focus::-moz-placeholder {
          color: #666 !important; }
        .site .sowBuilderSummary .summary .variables > input:first-child {
          width: 125px; }
        .site .sowBuilderSummary .summary .variables > input:last-child {
          width: 120px; }
        .site .sowBuilderSummary .summary .variables > input:focus {
          outline: 0; }
        .site .sowBuilderSummary .summary .variables > input + input {
          margin-left: 10px; }
  .site .sowBuilderSummary > .preview {
    display: none;
    border-top: 1px solid #ddd;
    margin-top: 20px !important;
    padding: 20px 20px 20px 27px !important;
    max-height: 400px;
    overflow-y: scroll;
    text-align: left;
    white-space: normal; }
    .site .sowBuilderSummary > .preview::-webkit-scrollbar {
      width: 14px; }
    .site .sowBuilderSummary > .preview::-webkit-scrollbar-thumb {
      position: absolute;
      background-color: #c1c1c1;
      border: 3px solid transparent;
      border-left-width: 4px;
      border-radius: 9px;
      background-clip: content-box;
      -webkit-transition: background-color 200ms ease;
      -moz-transition: background-color 200ms ease;
      -o-transition: background-color 200ms ease;
      transition: background-color 200ms ease; }
    .site .sowBuilderSummary > .preview::-webkit-scrollbar-thumb:hover, .site .sowBuilderSummary > .preview::-webkit-scrollbar-thumb:active {
      position: absolute;
      background-color: #7d7d7d; }
    .site .sowBuilderSummary > .preview > div {
      border-top: 1px solid #ddd;
      margin-top: 30px;
      padding-top: 25px; }
      .site .sowBuilderSummary > .preview > div:first-child {
        border-top: 0;
        margin: 0;
        padding: 0; }
    .site .sowBuilderSummary > .preview h5 {
      font-size: 14px;
      line-height: normal;
      font-weight: 400;
      margin: 0 5px 5px 0;
      color: #999;
      position: relative; }
      .site .sowBuilderSummary > .preview h5 .remove:after {
        position: absolute;
        z-index: 2;
        top: 2px;
        left: -16px;
        content: "\f056";
        color: red;
        font: normal normal normal 14px/1 FontAwesome;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        opacity: 0.75;
        -webkit-transition: opacity 200ms ease;
        -moz-transition: opacity 200ms ease;
        -o-transition: opacity 200ms ease;
        transition: opacity 200ms ease; }
      .site .sowBuilderSummary > .preview h5 .remove:hover {
        cursor: pointer; }
        .site .sowBuilderSummary > .preview h5 .remove:hover:before {
          color: #888; }
        .site .sowBuilderSummary > .preview h5 .remove:hover:after {
          opacity: 1; }
    .site .sowBuilderSummary > .preview .priceTime {
      margin-top: 0;
      font-size: 13px;
      line-height: 13px;
      margin-bottom: 0; }
      .site .sowBuilderSummary > .preview .priceTime span {
        color: #455260 !important;
        padding: 0 !important; }
        .site .sowBuilderSummary > .preview .priceTime span + span {
          /*border-left: 1px solid #ddd;
            margin-left: 7px !important;
            padding-left: 7px !important;*/ }
    .site .sowBuilderSummary > .preview p {
      margin-top: 10px;
      margin-bottom: 0;
      font-size: 10px;
      line-height: 14px;
      font-weight: normal !important; }
      .site .sowBuilderSummary > .preview p h2 {
        font-size: 10px; }
      .site .sowBuilderSummary > .preview p strong {
        font-weight: normal !important; }
    .site .sowBuilderSummary > .preview .productRow {
      margin-top: 20px; }
      .site .sowBuilderSummary > .preview .productRow label {
        font-size: 14px;
        line-height: normal;
        font-weight: 400;
        font-style: italic;
        margin: 0;
        padding-bottom: 5px;
        color: #666;
        display: block;
        width: 100%;
        border-bottom: 1px solid #eee; }
      .site .sowBuilderSummary > .preview .productRow .option {
        margin: 15px 0 0 20px; }
        .site .sowBuilderSummary > .preview .productRow .option ul {
          padding-left: 25px;
          margin-bottom: 10px; }
        .site .sowBuilderSummary > .preview .productRow .option:last-child {
          margin-bottom: 0; }
  .site .sowBuilderSummary > .download {
    visibility: hidden;
    height: 0;
    padding: 0;
    text-align: left; }
    .site .sowBuilderSummary > .download > * {
      visibility: hidden;
      height: 0; }
  .site .sowBuilderSummary.has-products .cta button {
    display: inline-block; }
    .site .sowBuilderSummary.has-products .cta button.preview {
      display: none; }
  .site .sowBuilderSummary.has-products.preview-showing {
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    -o-transition: all 200ms;
    transition: all 200ms;
    padding-bottom: 0; }
    .site .sowBuilderSummary.has-products.preview-showing .cta .preview:after {
      -webkit-font-smoothing: antialiased;
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
    .site .sowBuilderSummary.has-products.preview-showing > .preview {
      display: block;
      background-color: #fff;
      padding-bottom: 20px; }

.site.list .page .product-container {
  position: relative;
  margin-bottom: 20px; }
  .site.list .page .product-container > div {
    -webkit-transition: opacity 300ms;
    -moz-transition: opacity 300ms;
    -o-transition: opacity 300ms;
    transition: opacity 300ms;
    position: relative;
    height: 100%; }
    .site.list .page .product-container > div .card {
      color: #fff;
      border: 0;
      min-height: 100%;
      height: 150px; }
      .site.list .page .product-container > div .card .card-body {
        display: none; }
        .site.list .page .product-container > div .card .card-body .card-text {
          font-size: 11px;
          padding: 5px 0; }
    .site.list .page .product-container > div:hover .card .card-header {
      height: 0px; }
      .site.list .page .product-container > div:hover .card .card-header h5,
      .site.list .page .product-container > div:hover .card .card-header h6 {
        display: none; }
    .site.list .page .product-container > div:hover .card .card-body {
      margin-top: 0;
      padding-top: 5px;
      display: block; }
    .site.list .page .product-container > div:hover .card .card-footer {
      margin-bottom: -10px; }
    .site.list .page .product-container > div .sowBuilder {
      position: absolute;
      top: -10px;
      right: -10px;
      opacity: 0;
      pointer-events: none;
      -webkit-transition: opacity 200ms ease;
      -moz-transition: opacity 200ms ease;
      -o-transition: opacity 200ms ease;
      transition: opacity 200ms ease;
      z-index: 2; }
      .site.list .page .product-container > div .sowBuilder.active {
        opacity: 1;
        pointer-events: auto; }
      .site.list .page .product-container > div .sowBuilder button {
        position: relative;
        display: inline-block;
        color: #fff;
        white-space: nowrap;
        text-align: center;
        background: green;
        text-decoration: none;
        border-color: rgba(255, 255, 255, 0.75);
        border-radius: 3px;
        font-size: 11px;
        padding: 5px 12px 5px 10px !important;
        font-weight: 300;
        -webkit-transition: background 200ms ease;
        -moz-transition: background 200ms ease;
        -o-transition: background 200ms ease;
        transition: background 200ms ease; }
        .site.list .page .product-container > div .sowBuilder button:focus {
          outline: 0; }

.site.list .page .items {
  margin: 0;
  justify-content: center; }
  .site.list .page .items .product-container > div:before, .site.list .page .items .product-container > div:after {
    position: absolute;
    z-index: 2;
    content: "";
    top: 50%;
    left: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    /*background-color: $color-gray;*/ }
  .site.list .page .items .product-container > div:after {
    /*background: url(../assets/featured-icon.png) center center no-repeat;
              background-size: auto 22px;*/ }
  .site.list .page .items .product-container > div .card {
    overflow: hidden;
    padding: 15px 35px 0 35px; }
    .site.list .page .items .product-container > div .card:before {
      position: absolute;
      z-index: 2;
      content: "";
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 500px 0 0 210px;
      border-color: transparent transparent transparent #3d4647; }
    .site.list .page .items .product-container > div .card:after {
      position: absolute;
      z-index: 2;
      content: "";
      top: 0;
      right: -245px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 300px 220px 0;
      border-color: transparent #3d4647 transparent transparent; }
    .site.list .page .items .product-container > div .card.vrd {
      background-color: #ff4949; }
    .site.list .page .items .product-container > div .card.supportplan {
      background-color: #00afcc; }
    .site.list .page .items .product-container > div .card.successplan {
      background-color: #e2700c; }
    .site.list .page .items .product-container > div .card.successpath {
      background-color: #fdc973; }
    .site.list .page .items .product-container > div .card.rapidoutcome, .site.list .page .items .product-container > div .card.successservice {
      background-color: #5bb745; }
    .site.list .page .items .product-container > div .card.timebased {
      background-color: #0075be; }
    .site.list .page .items .product-container > div .card .card-header {
      padding: 0;
      text-align: center;
      flex-grow: 1; }
      .site.list .page .items .product-container > div .card .card-header .priceTime {
        color: #fff;
        font-size: 14px; }
        .site.list .page .items .product-container > div .card .card-header .priceTime span + span:before {
          color: #fff; }
    .site.list .page .items .product-container > div .card .card-body {
      flex-grow: unset;
      height: 150px; }
      .site.list .page .items .product-container > div .card .card-body .card-text {
        font-size: 11px; }
    .site.list .page .items .product-container > div .card .card-footer {
      text-align: center; }
      .site.list .page .items .product-container > div .card .card-footer button {
        background-color: #3d4647; }
  .site.list .page .items .product-container > div.vrd:after {
    background: url(../assets/vrd-icon.png) center center no-repeat;
    background-size: auto 65px; }
  .site.list .page .items .product-container > div.supportplan:after {
    background: url(../assets/supportplan-icon.png) center center no-repeat;
    background-size: auto 65px; }
  .site.list .page .items .product-container > div.successplan:after {
    background: url(../assets/successplan-icon.png) center center no-repeat;
    background-size: auto 65px; }
  .site.list .page .items .product-container > div.successpath:after {
    background: url(../assets/successpath-icon.png) center center no-repeat;
    background-size: auto 65px; }
  .site.list .page .items .product-container > div.rapidoutcome:after, .site.list .page .items .product-container > div.successservice:after {
    background: url(../assets/rapidoutcome-icon.png) center center no-repeat;
    background-size: auto 65px; }
  .site.list .page .items .product-container > div.timebased:after {
    background: url(../assets/timebased-icon.png) center center no-repeat;
    background-size: auto 65px; }
  .site.list .page .items .product-container > div:hover {
    opacity: 1; }
    .site.list .page .items .product-container > div:hover:before, .site.list .page .items .product-container > div:hover:after {
      display: none; }
    .site.list .page .items .product-container > div:hover .card {
      padding: 10px; }
      .site.list .page .items .product-container > div:hover .card:before, .site.list .page .items .product-container > div:hover .card:after {
        display: none; }
      .site.list .page .items .product-container > div:hover .card .card-footer {
        text-align: center; }
  .site.list .page .items .product-container.col-lg-12 > div:before, .site.list .page .items .product-container.col-lg-12 > div:after {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%); }
  .site.list .page .items .product-container.col-lg-12 > div .card:before {
    border-width: 400px 0 0 210px; }
  .site.list .page .items .product-container.col-lg-12 > div .card .card-body .card-text {
    font-size: 12px; }
  .site.list .page .items .product-container.col-lg-12 > div:hover {
    height: 100%; }
    .site.list .page .items .product-container.col-lg-12 > div:hover .card {
      min-height: 100%; }

.site.detail {
  background-image: url(../assets/background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  flex-direction: column; }
  .site.detail .navbar {
    background: #3d4647 !important; }
  .site.detail .detail.container .backButton a {
    color: #999; }
  .site.detail .detail.container .backButton:hover {
    text-decoration: none; }
    .site.detail .detail.container .backButton:hover a {
      color: #666;
      text-decoration: none; }
  .site.detail .detail.container .detailCardContainer {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    align-items: start;
    justify-items: start; }
    @media (max-width: 800px) {
      .site.detail .detail.container .detailCardContainer {
        grid-template-columns: auto;
        grid-template-rows: 1fr auto; } }
    .site.detail .detail.container .detailCardContainer .card {
      width: 100%;
      display: inline-block; }
      .site.detail .detail.container .detailCardContainer .card .card-header {
        padding-top: 2rem; }
        .site.detail .detail.container .detailCardContainer .card .card-header > img {
          margin-top: -18px;
          margin-bottom: 10px;
          max-width: calc(100% - 150px);
          height: auto; }
        .site.detail .detail.container .detailCardContainer .card .card-header .card-title {
          font-family: "CenturyGothic", Helvetica, Arial, sans-serif;
          font-weight: 500;
          margin: 0;
          padding: 10px 0; }
          .site.detail .detail.container .detailCardContainer .card .card-header .card-title .description {
            padding: 15px 0;
            font-size: 11px;
            color: #777;
            display: inline-block; }
        .site.detail .detail.container .detailCardContainer .card .card-header .card-subtitle {
          color: #666; }
        .site.detail .detail.container .detailCardContainer .card .card-header .cta {
          position: absolute;
          z-index: 1;
          top: -1px;
          right: -1px;
          width: auto;
          -webkit-transition: all 200ms ease;
          -moz-transition: all 200ms ease;
          -o-transition: all 200ms ease;
          transition: all 200ms ease; }
          .site.detail .detail.container .detailCardContainer .card .card-header .cta > button {
            margin: 15px 15px 0 0;
            color: #fff;
            white-space: nowrap;
            text-align: center;
            background: #00afcc;
            text-decoration: none;
            border: 0;
            border-radius: 3px;
            font-size: 12px;
            padding: 5px 15px !important;
            font-weight: 300;
            -webkit-transition: background 200ms ease;
            -moz-transition: background 200ms ease;
            -o-transition: background 200ms ease;
            transition: background 200ms ease; }
            .site.detail .detail.container .detailCardContainer .card .card-header .cta > button:hover {
              background: #0099b3; }
            .site.detail .detail.container .detailCardContainer .card .card-header .cta > button:focus {
              outline: 0; }
            .site.detail .detail.container .detailCardContainer .card .card-header .cta > button.added {
              background: green;
              position: relative; }
              .site.detail .detail.container .detailCardContainer .card .card-header .cta > button.added:before {
                position: absolute;
                z-index: 1;
                top: -4px;
                left: -4px;
                content: "\f111";
                color: #fff;
                font: normal normal normal 14px/1 FontAwesome;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                text-shadow: 0.5px 0.5px 0 white; }
              .site.detail .detail.container .detailCardContainer .card .card-header .cta > button.added:after {
                position: absolute;
                z-index: 2;
                top: -4px;
                left: -4px;
                content: "\f056";
                color: red;
                font: normal normal normal 14px/1 FontAwesome;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -webkit-transition: opacity 200ms ease;
                -moz-transition: opacity 200ms ease;
                -o-transition: opacity 200ms ease;
                transition: opacity 200ms ease; }
              .site.detail .detail.container .detailCardContainer .card .card-header .cta > button.added:hover {
                background: #009300; }
                .site.detail .detail.container .detailCardContainer .card .card-header .cta > button.added:hover:after {
                  opacity: 1; }
      .site.detail .detail.container .detailCardContainer .card .card-body .productDetailTabDisplay .tabList button:hover, .site.detail .detail.container .detailCardContainer .card .card-body .productDetailTabDisplay .tabList button.active {
        color: #5bb745; }
      .site.detail .detail.container .detailCardContainer .card .card-body .section .checkoption > div:hover {
        color: #5bb745; }
      .site.detail .detail.container .detailCardContainer .card .card-body .section .checkoption.checked {
        color: #5bb745; }
        .site.detail .detail.container .detailCardContainer .card .card-body .section .checkoption.checked input[type=checkbox]:after {
          color: #666; }
      .site.detail .detail.container .detailCardContainer .card .card-body .section .documents td:first-child a {
        color: #5bb745; }
        .site.detail .detail.container .detailCardContainer .card .card-body .section .documents td:first-child a:hover {
          color: #57b042; }
